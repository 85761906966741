define("discourse/plugins/discourse-randomized-banner/discourse/templates/connectors/above-main-container/randomized-banner", ["exports", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "@ember/template-factory"], function (_exports, _thisFallbackHelper, _tryLookupHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div id="randomized-banner-container" style="aspect-ratio:{{siteSettings.banner_aspect_ratio}} ; max-height:{{siteSettings.banner_max_height}}">
    <img id="randomized-banner" src="{{this.bannerSrc}}" fetchpriority="high" alt="forum banner"/>
  </div>
  
  */
  {
    "id": "1TrGpbkJ",
    "block": "[[[44,[[28,[37,1],null,[[\"siteSettings\"],[[28,[32,0],[\"siteSettings\"],null]]]]],[[[10,0],[14,1,\"randomized-banner-container\"],[15,5,[29,[\"aspect-ratio:\",[52,[30,1,[\"siteSettings\"]],[28,[30,1,[\"siteSettings\"]],null,null],[28,[32,1],[[30,0],\"siteSettings.banner_aspect_ratio\",\"[\\\"The `siteSettings` property path was used in the `discourse/plugins/discourse-randomized-banner/discourse/templates/connectors/above-main-container/randomized-banner.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.siteSettings}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]],\" ; max-height:\",[52,[30,1,[\"siteSettings\"]],[28,[30,1,[\"siteSettings\"]],null,null],[28,[32,1],[[30,0],\"siteSettings.banner_max_height\",\"[\\\"The `siteSettings` property path was used in the `discourse/plugins/discourse-randomized-banner/discourse/templates/connectors/above-main-container/randomized-banner.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.siteSettings}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]]],[12],[1,\"\\n  \"],[10,\"img\"],[14,1,\"randomized-banner\"],[15,\"src\",[29,[[30,0,[\"bannerSrc\"]]]]],[14,\"fetchpriority\",\"high\"],[14,\"alt\",\"forum banner\"],[12],[13],[1,\"\\n\"],[13]],[1]]],[1,\"\\n\"]],[\"maybeHelpers\"],false,[\"let\",\"hash\",\"if\"]]",
    "moduleName": "discourse/plugins/discourse-randomized-banner/discourse/templates/connectors/above-main-container/randomized-banner.hbs",
    "scope": () => [_tryLookupHelper.default, _thisFallbackHelper.default],
    "isStrictMode": false
  });
});